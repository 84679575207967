<template>
  <div>
  <table class="">
    <thead>
      <tr>
        <th width="25%">Produto</th>
        <th width="25%">CAS: Agente</th>
        <th width="10%">ACGIH</th>
        <th width="10%">NR 15</th>
        <th width="10%">LINACH</th>
        <th width="10%">ESOCIAL - Tabela 24</th>
        <th width="10%">DECRETO 3048/99 - Anexo IV</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="7">
          <div class="legends">
            <span>Legendas: </span>
            <span>
                <img width='18' height='14' src='../assets/images/consta.svg'  alt='Consta'/> consta
              </span>
            <span>
                <img width='14' height='14' src='../assets/images/naoConsta.svg'  alt='Não consta'/> não consta
              </span>
            <span>
                <img width='16' height='16' src='../assets/images/classificacao.svg'  alt='Em classificação'/> em classificação
              </span>
          </div>
        </td>
      </tr>

      <template v-for="product in legalConsultationAgentsGroupedByProduct">
        <tr v-for="item in product.legalSearches" :key="product.product._id">
          <template v-if="product.product._id !==  '-' ">
            <td
              v-if='item.index < 1'
              :rowspan="product.legalSearches.length"
            >
              <section v-if="product.product._id !==  '-'">
                <p class='word-break'>{{ product.product.name}}</p>
              </section>
            </td>
          </template>

          <template v-else>
            <td>
              <section >
                <p class='word-break'>-</p>
              </section>
            </td>
          </template>

          <template v-for="field in model.tableFieldsPrint">
            <td :key="`field_${field.key}`">
              <template v-if="!model.getExist(field.key, item)">
                {{model.getsearchId(field.key, item)}}
              </template>

              <template v-else>
                <img width='18' height='16' alt='' v-bind:src="require('../assets/images/'+model.getExist(field.key, item)+'.svg')" />
              </template>
            </td>
          </template>
        </tr>
      </template>
    </tbody>
  </table>

  </div>

</template>

<script>
import LegalSearchSummary from '@/models/LegalSearchSummary'
import api from '../utils/api'

export default {
  data() {
    return {
      model: LegalSearchSummary,
      selectedAgent: {},
      totalPage: 0,
      summary: [],
      legalConsultationAgentsGroupedByProduct: [],
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    agentsPerPage() {
      return this.$store.getters['legalSearch/agentsPerPage']
    }
  },
  methods: {
    async load(skip) {
      const { acgih, nr15, linach, esocial, decreto} = JSON.parse(localStorage.getItem('summaryMeta'))
      const orderBy = JSON.parse(localStorage.getItem('summaryMetaOrderBy'))
      const params = {
        skip,
        acgih,
        nr15,
        linach,
        esocial,
        decreto,
        orderBy
      }
      return (await api.get(`/legal-search/${this.user.id}`, {params})).data
    },
  },
  async mounted(){
    try {
      const summary = []
      const {meta, legalSearch} = await this.load(0)
      const legalConsultationAgentsGroupedByProduct = []
      summary.push(...legalSearch)
      if (meta.total > this.summary.length) {
        this.totalPage = Math.ceil(meta.total / this.agentsPerPage);
        for (let i = 1; i <= this.totalPage; i++) {
          const { legalSearch: legalSearchFor } = await this.load(i)
          summary.push(...legalSearchFor)

          const legalSearchGroupByProduct = legalSearchFor.reduce((group, agent) => {
            const product = agent.product && agent.product._id ? agent.product._id : `-`;
            group[product] = group[product] ? group[product] : [];
            group[product].push(agent);
            return group;
          }, {})

          const keys = Object.keys(legalSearchGroupByProduct)

          keys.forEach((legalSearchGroupBy) => {
            legalConsultationAgentsGroupedByProduct.push(
              {
                product: legalSearchGroupBy !== `-` ? legalSearchGroupByProduct[legalSearchGroupBy].find((agent) => agent.product._id.toString() === legalSearchGroupBy).product : { _id: `-`, name: ' ', description: '' },
                legalSearches: legalSearchGroupByProduct[legalSearchGroupBy].map((x, index) => {
                  const legalSearch = x
                  legalSearch.index = index
                  return legalSearch
                })
              }
            )
          })
          console.log(i)
          legalConsultationAgentsGroupedByProduct.sort((a, b) => a.product.name.localeCompare(b.product.name))
          this.legalConsultationAgentsGroupedByProduct.push(...legalConsultationAgentsGroupedByProduct)
        }
      }

      this.summary = summary

      setTimeout(() => {
        window.print()
      }, 2000)
      await this.$store.dispatch('userData/updatePrintedReports', this.user.id)
    } catch (e) {

    }

  },
}
</script>

<style lang="scss" scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
}

table {
  thead {
    tr {
      th {
        height: 80px;
        background-color: #022245!important;
        color: #FFFFFF;

        border: 1px solid #FFFFFF!important;

        text-align: center;

        padding: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
  tbody {
    tr {
      td {
        height: 40px;
        color: #000;

        border: 2px solid #ddd;

        text-align: center;
        .legends {
          display: flex;
          justify-content: center;
          gap: 16px;

          margin: 8px 0;

          span:nth-child(1) {
            font-weight: 700;
          }

          span {
            display: flex;
            align-items: center;
            gap: 8px;

            font-size: 18px;
          }
        }
      }
    }
  }
}

</style>
